.c-schedule {  }
.c-schedule__select {
  width: 100%;
  max-width: 320px;
  height: 32px;
  background: #fff;
  background: var(--theme-content-section-background-color);
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 16px;
  display: block;
  position: relative;
}
.c-schedule__select::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: 14px;
  right: 8px;
}
.c-schedule__select select {
  width: 100%;
  height: 100%;
  color: inherit;
  padding: 0 24px 0 8px;
  cursor: pointer;
}
.c-schedule__headline {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin-bottom: 16px;
}
.c-schedule__list + .c-schedule__headline {
  margin-top: 16px;
}
.c-schedule__list {

}
.c-schedule__list__item {
  width: 100%;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.c-schedule__list__item + .c-schedule__list__item {
  margin-top: 16px;
}
.c-schedule__list__item__time {
  width: 120px;
}
.c-schedule__list__item__body {
  width: calc(100% - 120px);
}
.c-schedule__list__item__body__title {
  font-size: 14px;
  font-weight: bold;
}
.c-schedule__list__item__body__title a {
  color: #0094CC;
  color: var(--theme-primary-color);
  text-decoration: underline;
}
.c-schedule__list__item__body__title a:hover {
  opacity: 0.8;
}
.c-schedule__list__item__body__text {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}
@media screen and (max-width:767px) {
  .c-schedule__list__item__time { width: 100%; margin-bottom: 8px; }
  .c-schedule__list__item__body { width: 100%; }
}
