.widget-multi-calendar,
.widget-multi-calendar__modal {
  font-family: Helvetica, Arial, Verdana, Roboto, 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro',
    'メイリオ', 'Meiryo', sans-serif;
  -webkit-text-size-adjust: 100%;
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  vertical-align: middle;
}
.widget-multi-calendar a,
.widget-multi-calendar__modal a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
.widget-multi-calendar input,
.widget-multi-calendar textarea,
.widget-multi-calendar select,
.widget-multi-calendar button,
.widget-multi-calendar__modal input,
.widget-multi-calendar__modal textarea,
.widget-multi-calendar__modal select,
.widget-multi-calendar__modal button {
  font-family: inherit;
  font-size: inherit;
  /* background: transparent; */
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.widget-multi-calendar-selectTitle {
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
}
.widget-multi-calendar-select {
  width: 100%;
  min-height: 32px;
  border: 1px solid #ddd;
  border-radius: 2px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-multi-calendar-select select {
  background: #fff;
}
.widget-multi-calendar-select:hover {
  /* background: rgba(60, 64, 67, 0.1); */
}
.widget-multi-calendar-select::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: 14px;
  right: 8px;
}
.widget-multi-calendar-select__item {
  width: 100%;
  height: 100%;
  color: inherit;
  padding-right: 24px;
  padding-left: 8px;
  cursor: pointer;
}

/*
body{
  background: #F2F2F2;
}
*/
.widget-multi-calendar--frame {
  width: 100%;
  max-width: 1024px;
}
.widget-multi-calendar {
  width: 100%;
  max-width: 1024px;
  border-radius: 2px;
  border: 1px solid #ddd;
  border-top: none;
  background: #fff;
  overflow: hidden;
  position: relative;
}
.widget-multi-calendar.dimmed {
  opacity: 0.4;
}
.widget-multi-calendar__header {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background: #0094CC;
  background: var(--theme-primary-color);
}
.widget-multi-calendar__header__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.widget-multi-calendar__header__close span {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  display: block;
  position: relative;
}
.widget-multi-calendar__header__close:hover span {
  background: rgba(255, 255, 255, 0.3);
}
.widget-multi-calendar__header__close span::before,
.widget-multi-calendar__header__close span::after {
  content: "";
  width: 16px;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  top: 11px;
  left: 4px;
}
.widget-multi-calendar__header__close span::before { transform: rotate(45deg); }
.widget-multi-calendar__header__close span::after { transform: rotate(-45deg); }
.widget-multi-calendar__body {
}
.widget-multi-calendar__body__tour {
  border-bottom: 2px solid #0094cc;
  border-bottom: 2px solid var(--theme-primary-color);
  background: #f9f9f9;
  padding: 16px;
}
.widget-multi-calendar__body__tour__title {
  font-size: 14px;
  font-weight: bold;
  color: #0094cc;
  color: var(--theme-primary-color);
  margin-bottom: 4px;
}
.widget-multi-calendar__body__tour__select {
  width: 100%;
  max-width: 400px;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #ddd;
  background: #fff;
  display: block;
  position: relative;
}
.widget-multi-calendar__body__tour__select::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: 18px;
  right: 8px;
}
.widget-multi-calendar__body__tour__select__item {
  background: transparent;
  width: 100%;
  height: 100%;
  color: inherit;
  padding-right: 24px;
  padding-left: 8px;
  cursor: pointer;
}
.widget-multi-calendar__body__main {
  width: 100%;
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.widget-multi-calendar__month {
  width: calc(50% - 8px);
}
.widget-multi-calendar__month__nav {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}
.widget-multi-calendar__month:last-child .widget-multi-calendar__month__nav:before {
  content: '';
  display: block;
  height: 100%;
  border-left: 1px solid #ddd;
  position: absolute;
  top: 0;
  left: -8px;
}
.widget-multi-calendar__month__nav__title {
  line-height: 32px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.widget-multi-calendar__month__nav__button {
  width: 64px;
  height: 32px;
  font-size: 12px;
  font-weight: bold;
  color: #0094cc;
  color: var(--theme-primary-color);
  border: 2px solid #0094cc;
  border: 2px solid var(--theme-primary-color);
  border-radius: 2px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  cursor: pointer;
}
.widget-multi-calendar__month__nav__button span {
  color: inherit;
}
.widget-multi-calendar__month__nav__button:disabled {
  color: #ddd;
  border: 2px solid #ddd;
}
.widget-multi-calendar__month__nav__button:hover {
  background: rgba(0, 148, 204, 0.1);
}
.widget-multi-calendar__month__nav__button:disabled:hover {
  background: rgba(0, 148, 204, 0);
}
.widget-multi-calendar__month__nav__button.prev {
  left: 0;
}
.widget-multi-calendar__month__nav__button.prev::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 8px 6px 0;
  border-color: transparent #0094cc transparent transparent;
  border-color: transparent var(--theme-primary-color) transparent transparent;
  margin-right: 4px;
}
.widget-multi-calendar__month__nav__button.prev:disabled::before {
  border-color: transparent #ddd transparent transparent;
}
.widget-multi-calendar__month__nav__button.next {
  right: 0;
}
.widget-multi-calendar__month__nav__button.next::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 8px;
  border-color: transparent transparent transparent #0094cc;
  border-color: transparent transparent transparent var(--theme-primary-color);
  margin-left: 4px;
}
.widget-multi-calendar__month__nav__button.next:disabled::after {
  border-color: transparent transparent transparent #ddd;
}
.widget-multi-calendar__month__nav__button.spOnly {
  display: none;
}
.widget-multi-calendar__month__title {
  width: 100%;
  height: 24px;
  font-size: 12px;
  border-collapse: collapse;
  background: rgba(205, 204, 0, 0.1);
  display: table;
  table-layout: fixed;
}
.widget-multi-calendar__month__title li {
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ddd;
  display: table-cell;
}
.widget-multi-calendar__month__week {
  width: 100%;
  height: 64px;
  font-size: 12px;
  border-collapse: collapse;
  display: table;
  table-layout: fixed;
}
.widget-multi-calendar__month__week li {
  vertical-align: top;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  display: table-cell;
  position: relative;
}
.widget-multi-calendar__month__week li.request,
.widget-multi-calendar__month__week li.instant {
  cursor: pointer;
}
.widget-multi-calendar__month__week li.promotion {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #ff0045 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.widget-multi-calendar__month__week li.request:hover {
  background: rgba(255, 204, 0, 0.2);
}
.widget-multi-calendar__month__week li.instant:hover {
  background: rgba(0, 148, 204, 0.1);
}
.past .widget-multi-calendar__month__week__date {
  color: #aaa;
}

.widget-multi-calendar__month__week__date {
  font-size: 12px;
  font-weight: bold;
  margin: 4px 0 0 4px;
}
.widget-multi-calendar__month__week__ic {
  width: 20px;
  height: 20px;
  margin: -4px auto 0 auto;
}
.widget-multi-calendar__month__week__ic img {
  width: inherit;
  height: inherit;
}
.widget-multi-calendar__month__week__price {
  width: 100%;
  font-size: 12px;
  letter-spacing: -0.01em;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.widget-multi-calendar__month__week__promo {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
}
.widget-multi-calendar__month__week__promo::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 24px 24px 0;
  border-color: transparent #f64834 transparent transparent;
}
.widget-multi-calendar__month__week__promo img {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
}
.widget-multi-calendar__body__comment {
  padding: 0 16px 16px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.widget-multi-calendar__body__comment__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.widget-multi-calendar__body__comment__item + .widget-multi-calendar__body__comment__item {
  margin-left: 32px;
}
.widget-multi-calendar__body__comment__item__ic {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.ic_request path {
  fill: var(--theme-secondary-color);
}
.ic_instant path {
  fill: var(--theme-primary-color);
}
.widget-multi-calendar__body__comment__item__ic img {
  width: inherit;
  height: inherit;
}
.widget-multi-calendar__body__comment__item__ic.promo {
  border-radius: 4px;
  background: #F64834;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widget-multi-calendar__body__comment__item__ic.promo img {
  width: 10px;
  height: 10px;
}
.widget-multi-calendar__body__comment__item__name {
  font-size: 12px;
  font-weight: bold;
  margin-right: 4px;
}
.widget-multi-calendar__body__comment__item__hatena {
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #aaa;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.widget-multi-calendar__body__comment__item__hatena:hover
  + .widget-multi-calendar__body__comment__item__info {
  display: block;
}
.widget-multi-calendar__body__comment__item__info {
  display: none;
  width: 240px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(60, 64, 67, 0.1);
  position: absolute;
  left: 0;
  bottom: 16px;
  padding: 6px;
  z-index: 1;
}
.widget-multi-calendar__booking__allowed__text {
  width: 100%;
  height: 20px;
  font-size: 12px;
  color: #aaa;
  margin-bottom: 8px;
  margin-left: 16px;
}

@media screen and (max-width: 768px) {
  .widget-multi-calendar--frame {
    padding: 0;
    margin: 0;
  }
  .widget-multi-calendar {
    border-radius: 0;
    border: 0;
    box-shadow: none;
  }
  .widget-multi-calendar__month__week__price {
    display: none;
  }
  .widget-multi-calendar__month__week {
    height: 48px;
  }
}
@media screen and (max-width: 600px) {
  /* body { background: transparent; } */
  .widget-multi-calendar {
    padding-bottom: 16px;
  }
  .widget-multi-calendar__month:not(:first-child) {
    display: none;
  }
  .widget-multi-calendar__month {
    width: 100%;
  }
  .widget-multi-calendar__month__nav__title {
    line-height: 40px;
  }
  .widget-multi-calendar__month__nav__button {
    width: 40px;
    height: 40px;
  }
  .widget-multi-calendar__month__nav__button::before {
    margin-right: 2px !important;
  }
  .widget-multi-calendar__month__nav__button::after {
    margin-left: 2px !important;
  }
  .widget-multi-calendar__month__nav__button span {
    display: none;
  }
  .widget-multi-calendar__month__nav__button.spOnly {
    display: flex;
  }
  .widget-multi-calendar__month__week__date {
    text-align: center;
    margin: 4px 0 0 0;
  }
  .widget-multi-calendar__month__week__ic {
    margin: 0 auto;
  }
  .widget-multi-calendar__body__comment {
    width: 240px;
    border: 2px solid #ddd;
    padding: 8px;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
  }
  .widget-multi-calendar__body__comment__item {
    width: 100%;
    padding: 0 8px;
  }
  .widget-multi-calendar__body__comment__item + .widget-multi-calendar__body__comment__item {
    border-top: 1px solid #ddd;
    margin-left: 0;
    padding-top: 8px;
    margin-top: 8px;
  }
}
