/*-- modal --*/
.widget-multi-calendar__modal--frame {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
}
.widget-multi-calendar__modal--frame.is-active {
  display: block;
}
.widget-multi-calendar__modal {
  width: calc(100% - 32px);
  max-width: 480px;
  background: #fff;
  margin: 16px auto;
  padding-bottom: 16px;
  transition: all 0.4s ease;
}
.widget-multi-calendar__modal__header {
  width: 100%;
  height: 40px;
  background: #0094cc;
  background: var(--theme-primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-multi-calendar__modal__header__title {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-left: 8px;
}
.widget-multi-calendar__modal__header__close {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.widget-multi-calendar__modal__header__close span {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  display: block;
  position: relative;
}
.widget-multi-calendar__modal__header__close:hover span {
  background: rgba(255, 255, 255, 0.3);
}
.widget-multi-calendar__modal__header__close span::before,
.widget-multi-calendar__modal__header__close span::after {
  content: '';
  width: 16px;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  top: 11px;
  left: 4px;
}
.widget-multi-calendar__modal__header__close span::before {
  transform: rotate(45deg);
}
.widget-multi-calendar__modal__header__close span::after {
  transform: rotate(-45deg);
}
.widget-multi-calendar__modal__body {
  overflow-y: scroll;
}
.widget-multi-calendar__modal__body__info {
  width: 100%;
  background: #f9f9f9;
  padding: 16px;
}
.widget-multi-calendar__modal__table {
  width: 100%;
}
.widget-multi-calendar__modal__table__item {
  width: 100%;
  display: table;
  border-collapse: collapse;
}
.widget-multi-calendar__modal__table__item + .widget-multi-calendar__modal__table__item {
  margin-top: -1px;
}
.widget-multi-calendar__modal__table__item__title {
  width: 96px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ddd;
  background: #eee;
  padding: 8px;
  display: table-cell;
}
.widget-multi-calendar__modal__table__item__body {
  vertical-align: middle;
  border: 1px solid #ddd;
  background: #fff;
  padding: 8px;
  display: table-cell;
}
.widget-multi-calendar__modal__price {
  padding-top: 8px;
}
.widget-multi-calendar__modal__price__item {
  width: 100%;
  min-height: 44px;
  font-size: 12px;
  padding: 0 8px;
  display: table;
}
.widget-multi-calendar__modal__price__item + .widget-multi-calendar__modal__price__item {
  border-top: 1px solid #ddd;
}
.widget-multi-calendar__modal__price__item__title {
  vertical-align: middle;
  display: table-cell;
}
.widget-multi-calendar__modal__price__item__money {
  width: 112px;
  text-align: right;
  vertical-align: middle;
  display: table-cell;
  word-break: keep-all;
}
.widget-multi-calendar__modal__price__item__money .normal {
}
.widget-multi-calendar__modal__price__item__money .discount {
  color: #f64834;
}
.widget-multi-calendar__modal__price__item__money .linethrough {
  font-size: 11px;
  text-decoration: line-through;
}
.widget-multi-calendar__modal__body__form {
  padding: 16px;
}
.widget-multi-calendar__modal__body__form__title {
  font-size: 14px;
  font-weight: bold;
  color: #0094cc;
  color: var(--theme-primary-color);
  margin-bottom: 4px;
}
.widget-multi-calendar__modal__body__form__number {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
}
.widget-multi-calendar__modal__body__form__number li {
  width: calc((100% - 16px) / 2);
  margin-bottom: 8px;
}
.widget-multi-calendar__modal__body__form__number li:nth-child(odd) {
  margin-right: 16px;
}
.widget-multi-calendar__modal__body__form__time {
  position: relative;
}
.widget-multi-calendar__modal__body__form__time__badge_rq {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 20px;
  border-radius: 10px;
  background: #0094cc;
  background: var(--theme-primary-color);
  padding: 0 8px;
  position: absolute;
  bottom: 4px;
  right: 24px;
}
.widget-multi-calendar__modal__body__form__time__badge {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 20px;
  border-radius: 10px;
  background: #0094cc;
  background: var(--theme-primary-color);
  padding: 0 8px;
  position: absolute;
  bottom: 4px;
  right: 24px;
}
.widget-multi-calendar__modal__body__button {
  width: calc(100% - 32px);
  max-width: 320px;
  height: 48px;
  font-size: 18px !important;
  font-weight: bold;
  color: #fff;
  background: #ffcc00;
  background: var(--theme-secondary-color);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  margin: 16px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.widget-multi-calendar__modal__body__button:hover {
  opacity: 0.8;
}
.widget-multi-calendar__modal__body__button:disabled {
  background: #ddd;
}
.widget-multi-calendar__number {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
}
.widget-multi-calendar__number > li {
  width: calc((100% - 16px) / 2);
  margin-bottom: 8px;
}
.widget-multi-calendar__number > li:nth-child(odd) {
  margin-right: 16px;
}
.widget-multi-calendar__number__display {
  width: 260px;
  text-align: center;
  color: #0094CC;
  border-radius: 4px;
  border: 1px solid #0094CC;
  padding: 4px;
  margin: 0 auto;
  position: relative;
  word-break: break-all;
  cursor: pointer;
}
.widget-multi-calendar__number__overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.widget-multi-calendar__number__overlay.is-active { display: block; }
.widget-multi-calendar__number__select {
  width: 100%;
  max-width: 704px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,20%);
  background: #fff;
  padding: 16px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 100;
}
.widget-multi-calendar__number__select ul {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
}
.widget-multi-calendar__number__select ul li {
  width: calc((100% - 16px) / 2);
  margin-bottom: 8px;
}
.widget-multi-calendar__number__select ul li:nth-child(odd) {
  margin-right: 16px;
}
.widget-multi-calendar__number__select ul li > p {

}
.widget-multi-calendar__number__select {
  width: 100%;
  max-width: 704px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,20%);
  background: #fff;
  padding: 16px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 100;
}
.widget-multi-calendar__number__select.is-active { display: block; }
.widget-multi-calendar__number__select ul {
  
}
.widget-multi-calendar__number__select ul li + li {
  margin-top: 16px;
}


@media screen and (max-width: 768px) {
  .widget-multi-calendar__modal--frame {
    overflow-y: scroll;
  }
  .widget-multi-calendar__modal {
    width: 100%;
    border-radius: 0px;
    background: #fff;
    margin: 0px;
    padding-bottom: 0px;
    transition: all 0.4s ease;
  }
}
