.container {
  box-shadow: 0px 4px 17.3px 0px #e6ebff;
  background-color: var(--White, #fff);
  display: flex;
  align-items: flex-end;
  gap: 40px;
  font-size: 16px;
  font-weight: 600;
  padding: 50px 80px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}
.text-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title {
  color: var(--Dark, #393d3f);
  text-transform: capitalize;
  font: 28px/123% Poppins, sans-serif;
}
.body {
  color: var(--Dark, #393d3f);
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 24px;
  margin-top: 24px;
}
.link {
  color: #00c2d8;
}
.actions {
  flex-shrink: 0;
  display: flex;
  margin-top: 40px;
  gap: 20px;
  justify-content: space-between;
}
.button {
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 53px;
  padding: 18px 40px;
  cursor: pointer;
  min-width: fit-content;
}
.accept-button {
  background-color: var(--Dark, #393d3f);
  color: var(--White, #fff);
}
.reject-button {
  border-color: rgba(57, 61, 63, 1);
  border-style: solid;
  border-width: 2px;
  color: var(--Dark, #393d3f);
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 40px;
  }
  .actions {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .button {
    width: 100%;
    padding: 12px 24px;
    text-align: center;
  } 
}
