.c-sns {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.c-sns div {
  margin-left: 4px;
  margin-right: 0px;
}
.c-sns__icons {
  display: flex;
  justify-content: flex-start;
  padding-left: 32px;
  padding-bottom: 32px;
}
.c-sns__icon {
  margin-right: 12px;
}
@media screen and (max-width: 768px) {
  .c-sns {
    margin-top: 0px;
    justify-content: flex-start;
    padding: 0px 16px;
    flex-wrap: wrap;
  }
  .c-sns div {
    margin-left: 0px;
    margin-right: 4px;
  }
  .c-sns__icons {
    padding-left: 16px;
    padding-bottom: 16px;
  }
}
