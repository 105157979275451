.c-list {
}
.c-list__item {
  width: 100%;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-list__item + .c-list__item {
  padding-top: 16px;
}
.c-list__item__title {
  width: 160px;
  font-size: 14px;
  font-weight: bold;
  color: #0094cc;
  color: var(--theme-primary-color);
}
.c-list__item__body {
  width: calc(100% - 160px);
  padding-left: 24px;
}
.c-list__item__body li {
  list-style: disc;
}
@media screen and (max-width: 768px) {
  .c-list__item__title {
    width: 100%;
    margin-bottom: 16px;
  }
  .c-list__item__body {
    width: 100%;
  }
}
