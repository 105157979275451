.widget-calendar__promotion {
  margin: 8px 16px 0px 16px;
  overflow: hidden;
  position: relative;
}
.widget-calendar__promotion.is-close {
  max-height: 170px;
}
.widget-calendar__promotion__list {
  padding-bottom: 8px;
}
.widget-calendar__promotion__list__item {
}
.widget-calendar__promotion__list__item + li {
  margin-top: 16px;
}
.widget-calendar__promotion__list__item__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.widget-calendar__promotion__list__item__header img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.widget-calendar__promotion__list__item__header p {
  font-size: 14px;
  font-weight: bold;
  /*
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  */
}
.widget-calendar__promotion__list__item__body {
  margin-top: 8px;
}
.widget-calendar__promotion__list__item__body__item {
  border-bottom: 1px solid #ddd;
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-calendar__promotion__list__item__body__item:first-child {
  border-top: 1px solid #ddd;
}
.widget-calendar__promotion__list__item__body__item__ttl {
  font-weight: bold;
}
.widget-calendar__promotion__list__item__body__item__price {
  text-align: right;
}
.widget-calendar__promotion__list__item__body__item__price .normal {
  font-size: 11px;
  text-decoration: line-through;
  color: #888;
  display: block;
}
.widget-calendar__promotion__list__item__body__item__price .discount {
  color: #ff0045;
  font-weight: bold;
  display: block;
}
.widget-calendar__promotion__more {
  width: 100%;
  height: 48px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  bottom: 0;
}
.widget-calendar__promotion__more.is-close {
  position: absolute;
}
.widget-calendar__promotion__more.is-open {
  position: relative;
}
.widget-calendar__promotion__more__btn {
  width: 160px;
  height: 32px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
