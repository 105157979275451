.cdx-list--ordered * {
  list-style: decimal;
}

.cdx-list--unordered * {
  list-style: disc;
}

/*
.codex-editor {
  background-color: #f9f9f9;
  border: 1px solid #cfdae1;
}
*/

.image-tool__image {
  display: flex;
  justify-content: center;
}

.editorjs-button-toggle {
  display: none;
}
