.base-feature,
.base-category {
  width: 100%;
  height: 240px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-feature::before,
.base-category::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
}
.base-feature__pic,
.base-category__pic {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.base-feature__body,
.base-category__body {
  max-width: 800px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 2;
}
.base-feature__body__headline,
.base-category__body__headline {
  font-size: 32px;
  font-weight: bold;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.base-feature__body__description,
.base-category__body__description {
  font-size: 14px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-top: 8px;
}
@media screen and (max-width: 768px) {
  .base-feature__body,
  .base-category__body {
    width: 100%;
    text-align: left;
    padding: 0 16px;
  }
  .base-feature__body__headline,
  .base-category__body__headline {
    font-size: 24px;
  }
}

.base-point {
  background: #fff;
  background: var(--theme-content-section-background-color);
  padding: 32px 0;
}
.base-point__inner {
  width: 100%;
  max-width: 1280px;
  padding: 0 32px;
  margin: 0 auto;
}
.base-point__headline {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 32px;
  color: var(--theme-subheader-color);
}
.base-point__body {
}
.base-point__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.base-point__list__item {
  width: calc((100% - 64px) / 3);
}
.base-point__list__item__pic {
  width: 100%;
  height: 220px;
  position: relative;
}
.base-point__list__item__pic img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 16px;
}
.base-point__list__item__pic__label {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 8px;
  left: 8px;
  font-weight: bold;
  color: #fff;
}
.base-point__list__item__pic__label span:first-child {
  font-size: 11px;
  line-height: 11px;
  margin-bottom: 2px;
  display: block;
}
.base-point__list__item__pic__label span:last-child {
  font-size: 24px;
  line-height: 24px;
  display: block;
}
.base-point__list__item__ttl {
  font-size: 18px;
  font-weight: bold;
  margin-top: 8px;
}
.base-point__list__item__description {
  margin-top: 4px;
}
.base-point__more {
  display: none;
}
@media screen and (max-width: 768px) {
  .base-point__inner {
    padding: 0 16px;
  }
  .base-point__headline {
    font-size: 20px;
    text-align: left;
    margin-bottom: 16px;
  }
  .base-point__body {
    width: 100%;
  }
  .base-point__body.is-close {
    max-height: 560px;
    overflow: hidden;
    position: relative;
  }
  .base-point__list {
    flex-direction: column;
  }
  .base-point__list__item {
    width: 100%;
  }
  .base-point__list__item + .base-point__list__item {
    margin-top: 32px;
  }
  .base-point__more {
    width: 100%;
    height: 48px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 0;
    bottom: 0;
  }
  .base-point__more.is-close {
    position: absolute;
  }
  .base-point__more.is-open {
    position: relative;
  }
  .base-point__more__btn {
    width: 160px;
    height: 32px;
    font-size: 14px;
    font-weight: bold;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
