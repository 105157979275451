.base-single__header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.base-single__title {
  position: relative;
}
.base-single__title__h1 {
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
  padding-top: 16px;
  /* padding-bottom: 16px; */
  position: relative;
  z-index: 1;
}
.base-single__title::after {
  content: '';
  display: block;
  width: 300vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 150%;
  right: 150%;
  margin-left: -150vw;
  margin-right: -150vw;
}
.base-single__headerinfo {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 8px 0 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.base-single__headerinfo__labels {
  font-size: 14px;
  font-weight: bold;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.base-single__headerinfo__labels__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-single__headerinfo__labels__item + div {
  margin-left: 16px;
}
.base-single__headerinfo__labels__item.ranking {
  color: #ffb300;
}
.base-single__headerinfo__labels__item.recommend {
  color: #2cb582;
}
.base-single__headerinfo__labels__item.promotion {
  color: #ff0045;
}
.base-single__headerinfo__labels__item img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.base-single__headerinfo__other {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.base-single__headerinfo__other__review {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 16px;
}
.base-single__headerinfo__other__review img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.base-single__headerinfo__other__review span:first-of-type {
  font-size: 16px;
  margin-right: 4px;
}
.base-single__headerinfo__other__review span:last-of-type {
  font-size: 16px;
  color: #888;
}
.base-single__headerinfo__other__favo {
}
.base-single__headerinfo__other__favo label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.base-single__headerinfo__other__favo input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.base-single__headerinfo__other__favo img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.base-single__headerinfo__other__favo img.off {
  display: block;
  opacity: 0.6;
}
.base-single__headerinfo__other__favo img.on {
  display: none;
}
.base-single__headerinfo__other__favo p {
  color: #aaa;
}
.base-single__headerinfo__other__favo input:checked ~ img.off {
  display: none;
}
.base-single__headerinfo__other__favo input:checked ~ img.on {
  display: block;
}
.base-single__headerinfo__other__favo input:checked ~ p {
  color: #ff0045;
}
.base-single__2ndheaderinfo {
  display: flex;
}
.base-single__2ndheaderinfo__left {
}
.base-single__2ndheaderinfo__right {
  margin-top: 8px;
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .base-single__headerinfo {
    order: 1;
    padding: 16px 0;
  }
  .base-single__headerinfo__labels {
    width: calc(100% - 32px);
    font-size: 12px;
    padding: 8px;
    margin: 0 auto 16px auto;
  }
  .base-single__headerinfo__other {
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
  }
  .base-single__headerinfo__other__review {
    margin-right: auto;
  }
  .base-single__title {
    order: 2;
  }
  .base-single__title__h1 {
    width: 100%;
    font-size: 18px;
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .base-single__title::after {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .base-single__2ndheaderinfo {
    flex-direction: column;
  }
  .base-single__2ndheaderinfo__right {
    margin-left: 0;
  }
}
