.base-ranking,
.base-recommend {
  max-width: 100%;
  border-bottom: 1px solid #ddd;
  background: #fff;
  background: var(--theme-content-section-background-color);
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-ranking__body,
.base-recommend__body {
  max-width: 800px;
}
.base-ranking__headline,
.base-recommend__headline {
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-ranking__headline > div,
.base-recommend__headline > div {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.base-ranking__headline img,
.base-ranking__headline svg,
.base-recommend__headline img,
.base-recommend__headline svg {
  width: inherit;
  height: inherit;
}
.base-ranking__headline h2,
.base-recommend__headline h2 {
  font-size: 32px;
  font-weight: bold;
}
.base-ranking__description,
.base-recommend__description {
  margin-top: 16px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .base-ranking,
  .base-recommend {
    padding: 16px;
  }
  .base-ranking__headline img,
  .base-recommend__headline img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
  .base-ranking__headline h2,
  .base-recommend__headline h2 {
    font-size: 24px;
  }
  .base-ranking__description,
  .base-recommend__description {
    margin-top: 8px;
    text-align: left;
  }
}
