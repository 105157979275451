.c-promotion {
  position: relative;
  overflow: hidden;
}
.c-promotion.is-close {
  max-height: 600px;
}
.c-promotion__list {
}
.c-promotion__list__item {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ece0cb;
  background: #fff8ec;
  padding: 16px;
}
.c-promotion__list__item + li {
  margin-top: 16px;
}
.c-promotion__list__item__header {
  border-bottom: 1px solid #ece0cb;
  padding-bottom: 12px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-promotion__list__item__header__label {
  width: 96px;
  font-size: 12px;
  font-weight: bold;
  color: #ff0045;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.c-promotion__list__item__header__label img {
  width: 40px;
  height: 40px;
}
.c-promotion__list__item__header__ttl {
  font-size: 18px;
  font-weight: bold;
  margin-left: 8px;
}
.c-promotion__list__item__body {
}
.c-promotion__list__item__body__description {
  margin-bottom: 8px;
}
.c-promotion__list__item__body__period {
  margin-bottom: 8px;
}
.c-promotion__list__item__body__period p {
  font-weight: bold;
  margin-right: 32px;
  display: inline-block;
}
.c-promotion__list__item__body__target {
  width: 100%;
  background: #fff;
  padding: 16px;
}
.c-promotion__list__item__body__target__item {
  width: 100%;
  display: table;
}
.c-promotion__list__item__body__target__item + li {
  border-top: 1px solid #ddd;
  margin-top: 16px;
  padding-top: 16px;
}
.c-promotion__list__item__body__target__item__ttl {
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
  display: table-cell;
}
.c-promotion__list__item__body__target__item__main {
  text-align: right;
  vertical-align: middle;
  display: table-cell;
}
.c-promotion__list__item__body__target__item__main__discountprice {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.c-promotion__list__item__body__target__item__main__discountprice .percent {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #ff0045;
  padding: 2px 4px;
  display: inline-block;
  margin-right: 16px;
}
.c-promotion__list__item__body__target__item__main__discountprice .price {
  font-size: 18px;
  font-weight: bold;
  color: #ff0045;
}
.c-promotion__list__item__body__target__item__main__normalprice {
  font-size: 14px;
  font-weight: bold;
  text-decoration: line-through;
  color: #aaa;
}
.c-promotion__more {
  width: 100%;
  height: 48px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  bottom: 0;
}
.c-promotion__more.is-close {
  position: absolute;
}
.c-promotion__more.is-open {
  position: relative;
}
.c-promotion__more__btn {
  width: 160px;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .c-promotion__list__item {
    padding: 8px;
  }
  .c-promotion__list__item__header {
    flex-direction: column;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  .c-promotion__list__item__header__ttl {
    font-size: 14px;
    margin-left: 0;
    margin-top: 8px;
  }
  .c-promotion__list__item__body__period p {
    margin-right: 0px;
  }
  .c-promotion__list__item__body__description {
    font-size: 12px;
  }
  .c-promotion__list__item__body__target__item__ttl {
    font-size: 14px;
  }
  .c-promotion__list__item__body__target__item__main__discountprice {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .c-promotion__list__item__body__target__item__main__discountprice .percent {
    margin-right: 0;
  }
  .c-promotion__list__item__body__target__item__main__discountprice .price {
    font-size: 16px;
  }
  .c-promotion__list__item__body__target__item__main__normalprice {
    font-size: 12px;
  }
}
