.base-h1 {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  top: 6px;
  left: 32px;
  z-index: 101;
}
.pmp-base-main {
  width: 100%;
  min-height: calc(100vh - 64px - 160px - 64px);
  margin-bottom: 64px;
  display: block;
}
.base-headline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-headline.center {
  justify-content: center;
}
.base-headline > div {
  width: 40px;
  height: 40px;
  margin-right: 4px;
}
.base-headline img,
.base-headline svg {
  width: inherit;
  height: inherit;
}
.base-headline h2 {
  font-size: 28px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .base-h1 {
    font-size: 10px;
    background: #fff;
    padding: 4px 8px;
    position: static;
  }
  .base-headline,
  .base-headline.center {
    justify-content: flex-start;
  }
  .base-headline h2 {
    font-size: 20px;
  }
  .base-headline img {
    width: 28px;
    height: 28px;
  }
}
.base-top {
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-top::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
}
.base-top__pic {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.base-top__body {
  max-width: 800px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 2;
}
.base-top__body__headline {
  font-size: 48px;
  font-weight: bold;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.base-top__body__description {
  font-size: 14px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-top: 8px;
}
.base-topNav {
  width: 100%;
  height: 64px;
  background: #fff;
  background: var(--theme-content-section-background-color);
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.base-topNav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.base-topNav__list__item {
  width: 176px;
  line-height: 48px;
  text-align: center;
  border-right: 1px solid #ddd;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-topNav__list__item:first-child {
  border-left: 1px solid #ddd;
}
.base-topNav__list__item > div {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.base-topNav__list__item img,
.base-topNav__list__item svg {
  width: inherit;
  height: inherit;
}
.base-topNav__arrow {
  display: none;
}
.base-topRanking,
.base-topRecommend {
  padding: 32px 0;
}
.base-topRecommend {
}
.base-topRanking__header,
.base-topRecommend__header {
  width: 100%;
  padding: 0 32px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-topRanking__header__btn,
.base-topRecommend__header__btn {
  width: 112px;
  line-height: 32px;
  font-size: 18px;
  font-weight: bold;
  margin-left: auto;
  position: relative;
}
.base-topRanking__header__btn::after,
.base-topRecommend__header__btn:after {
  content: '';
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 6px);
  right: 4px;
  border-top-width: 3px;
  border-right-width: 3px;
  border-top-style: solid;
  border-right-style: solid;
}
.base-topCategory,
.base-topFeature {
  width: 100%;
  padding: 32px 0;
}
.base-topFeature {
  /*
  background: #fff;
  background: var(--theme-content-section-background-color);
  */
}
.base-topCategory__inner,
.base-topFeature__inner {
  width: 100%;
  max-width: 1280px;
  padding: 0 32px;
  margin: 0 auto;
}
.base-topCategory__header,
.base-topFeature__header {
  margin-bottom: 32px;
}
.base-topCategory__list,
.base-topFeature__list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.base-topCategory__list__item,
.base-topFeature__list__item {
  width: calc((100% - 64px) / 3);
  margin-bottom: 32px;
}
.base-topCategory__list__item:not(:nth-child(3n)),
.base-topFeature__list__item:not(:nth-child(3n)) {
  margin-right: 32px;
}
@media screen and (max-width: 768px) {
  .base-top {
    height: 320px;
  }
  .base-top__body {
    width: 100%;
    text-align: left;
    padding: 0 16px;
  }
  .base-top__body__headline {
    font-size: 24px;
  }
  .base-top__body__description {
  }
  .base-topNav {
  }
  .base-topNav__list {
    justify-content: flex-start;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .base-topNav__arrow {
    width: 16px;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
  .base-topNav__arrow::before {
    content: '';
    width: 16px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    position: absolute;
    top: 0;
    left: -16px;
  }
  .base-topNav__arrow::after {
    content: '';
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 6px);
    right: 4px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-top-style: solid;
    border-right-style: solid;
  }
  .base-topRanking__header,
  .base-topRecommend__header,
  .base-topCategory__header,
  .base-topFeature__header {
    padding: 0 16px;
    margin-bottom: 16px;
  }
  .base-topRanking__header__btn,
  .base-topRecommend__header__btn {
    width: 88px;
    font-size: 14px;
    margin-right: -8px;
  }
  .base-topRanking__header__btn::after,
  .base-topRecommend__header__btn::after {
    border-top-width: 2px;
    border-right-width: 2px;
  }
  .base-topCategory__inner,
  .base-topFeature__inner {
    padding: 0;
  }
  .base-topCategory__list,
  .base-topFeature__list {
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
  }
  .base-topCategory__list__item,
  .base-topFeature__list__item {
    width: 300px;
    margin-bottom: 32px;
    flex-shrink: 0;
  }
  .base-topCategory__list__item,
  .base-topCategory__list__item:not(:nth-child(3n)),
  .base-topFeature__list__item:not(:nth-child(3n)) {
    margin-left: 16px;
    margin-right: 0;
  }
  .base-topCategory__list__item:last-child {
    width: calc(300px + 16px);
    padding-right: 16px;
  }
}
