.c-point {
}
.c-point__item {
}
.c-point__item + .c-point__item {
  margin-top: 16px;
}
.c-point__item__title {
  font-size: 16px;
  font-weight: bold;
  color: #0094cc;
  color: var(--theme-primary-color);
  margin-bottom: 8px;
}
.c-point__item__body {
}
