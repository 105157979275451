a.linkable-textarea-link {
  overflow-wrap: anywhere;
}
a.linkable-textarea-link:link {
  color: #0000ff;
}
a.linkable-textarea-link:visited {
  color: #000080;
}
a.linkable-textarea-link:hover {
  color: #ff0000;
}
a.linkable-textarea-link:active {
  color: #ff8000;
}
