.c-card {
  width: 100%;
  max-width: 400px;
  background: #fff;
  background: var(--theme-content-section-background-color);
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: hidden;
  display: block;
  position: relative;
  transition: all 0.4s ease;
  height: 100%
}
.c-card:hover {
  background: #f6f6f6;
}
.c-card__pic {
  width: 100%;
  height: 240px;
  position: relative;
}
.c-card__pic img {
  width: inherit;
  height: inherit;
  /* background: #eee; */
  object-fit: cover;
}
.c-card__pic__tag {
  width: 100%;
  padding: 4px 8px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap-reverse;
}
.c-card__pic__tag object {
  font-size: 12px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  padding: 2px 4px;
  margin-bottom: 4px;
  margin-right: 4px;
}
.c-card__pic__tag a {
}
.c-card__info {
  padding: 16px;
}
.c-card__info__ttl {
  height: 54px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.c-card__info__desc {
  height: 42px;
  font-size: 14px;
  line-height: 1.5;
  color: #aaa;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 8px;
}
.c-card__info__week {
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
  color: #aaa;
  margin-top: 8px;
}
.c-card__info__bottom {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.c-card__info__bottom__place {
  width: calc(100% - 120px);
  font-size: 14px;
  font-weight: bold;
  color: #ffcc00;
  color: var(--theme-secondary-color);
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.c-card__info__bottom__plice {
  width: 120px;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}
.c-card__header__star {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-card__header__star img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.c-card__header__star span:nth-of-type(1) {
  margin-right: 2px;
}
.c-card__header__star span:nth-of-type(2) {
  color: #888;
}
